/**
 * Created by tudou on 2020/5/7 22:19.
 */
import axios from '../libs/axios';

export const kartList = (data) => {
  return axios.post('/kart/list', data);
};

export const kartInfo = (data) => {
  return axios.post('/kart/info', data);
};

// 支付配置
export const saveHsPay= (data) => {
  return axios.post('/api/trd/shop/saveHsPay', data);
};

// 门店配置
export const saveConfig= (data) => {
  return axios.post('/api/trd/shop/saveConfig', data);
};

// 微信配置
export const saveWx= (data) => {
  return axios.post('/api/trd/shop/saveWx', data);
};

// 获取系统配置
export const storeInfo = (data) => {
  return axios.get('/api/trd/shopconfig/info', data);
};
