<template>
  <div class="base-content">
    <form-frame
      :back-visible="false"
      :reset-visible="false"
      :submit-visible="false"
    >
      <div slot="info">
        <span class="mr-20">门店编号：{{form.shopId}}</span>
        <span class="mr-20">门店状态：{{form.statusName}}</span>
      </div>
      <div class="form-bg" slot="content">
        <a-card title="门店信息">
          <div slot="extra">
            <span v-show="!form.editable">
              <a-button size="small" type="danger" @click="handleEdit()">编辑</a-button>
            </span>
            <span v-show="form.editable">
              <a-button size="small" type="primary" @click="handleSave()">保存</a-button>
              <a-divider type="vertical"></a-divider>
              <a-button size="small" @click="handleCancel()">取消</a-button>
            </span>
          </div>
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="门店名称" prop="shopName">
                  <a-input v-model="form.shopName" :maxLength="50" placeholder="请输入" allowClear :disabled="!form.editable"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="联系人" prop="linkman">
                  <a-input v-model="form.linkman" :maxLength="50" placeholder="请输入" allowClear :disabled="!form.editable"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="联系电话" prop="mobile">
                  <a-input v-model="form.mobile" :maxLength="50" placeholder="请输入" allowClear :disabled="!form.editable"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="地址" prop="address">
                  <a-input v-model="form.address" :maxLength="150" placeholder="请输入" allowClear :disabled="!form.editable"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="备注">
                  <a-input v-model="form.remark" type="textarea" :auto-size="{ minRows: 4, maxRows: 6 }" placeholder="请输入" allowClear :maxLength="10" :disabled="!form.editable"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
        <a-card class="mt-20" title="支付配置">
          <div slot="extra">
            <span v-show="!hsPay.editable">
              <a-button size="small" type="danger" @click="handleEdit('hsPay')">编辑</a-button>
            </span>
            <span v-show="hsPay.editable">
              <a-button size="small" type="primary" @click="handleSave('hsPay')">保存</a-button>
              <a-divider type="vertical"></a-divider>
              <a-button size="small" @click="handleCancel('hsPay')">取消</a-button>
            </span>
          </div>
          <a-form-model
            ref="hsPay"
            :model="hsPay"
            :rules="paymentRules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="商户号" prop="hsSubMerId">
                  <a-input v-model="hsPay.hsSubMerId" :maxLength="50" placeholder="请输入" allowClear :disabled="!hsPay.editable"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="集成商标识" prop="hsProxyId">
                  <a-input v-model="hsPay.hsProxyId" :maxLength="50" placeholder="请输入" allowClear :disabled="!hsPay.editable"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
            </a-row>
            <!--<a-row>-->
              <!--<a-col :span="10">-->
                <!--<a-form-model-item label="私钥" prop="hsPrivateKey">-->
                  <!--<a-upload-->
                    <!--name="file"-->
                    <!--:disabled="!hsPay.editable"-->
                    <!--:file-list="fileList1"-->
                    <!--:headers="headers"-->
                    <!--:action="action"-->
                    <!--@change="handleChange($event, 1)"-->
                  <!--&gt;-->
                    <!--<a-button :disabled="!hsPay.editable"> <a-icon type="upload" /> 点击上传 </a-button>-->
                  <!--</a-upload>-->
                <!--</a-form-model-item>-->
              <!--</a-col>-->
              <!--<a-col :span="10">-->
                <!--<a-form-model-item label="公钥" prop="hsPublicKey">-->
                  <!--<a-upload-->
                    <!--name="file"-->
                    <!--:disabled="!hsPay.editable"-->
                    <!--:file-list="fileList2"-->
                    <!--:headers="headers"-->
                    <!--:action="action"-->
                    <!--@change="handleChange($event, 2)"-->
                  <!--&gt;-->
                    <!--<a-button :disabled="!hsPay.editable"> <a-icon type="upload" /> 点击上传 </a-button>-->
                  <!--</a-upload>-->
                <!--</a-form-model-item>-->
              <!--</a-col>-->
            <!--</a-row>-->
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="扩展账号" prop="extAccount">
                  <a-input v-model="hsPay.extAccount" :maxLength="50" placeholder="请输入" allowClear :disabled="!hsPay.editable"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
        <a-card class="mt-20" title="公众号配置">
          <div slot="extra">
            <span v-show="!wxmp.editable">
              <a-button size="small" type="danger" @click="handleEdit('wxmp')">编辑</a-button>
            </span>
            <span v-show="wxmp.editable">
              <a-button size="small" type="primary" @click="handleSave('wxmp')">保存</a-button>
              <a-divider type="vertical"></a-divider>
              <a-button size="small" @click="handleCancel('wxmp')">取消</a-button>
            </span>
          </div>
          <a-form-model
            ref="wxmp"
            :model="wxmp"
            :rules="wxmpRules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="开发者ID" prop="wxAppId">
                  <a-input v-model="wxmp.wxAppId" :maxLength="50" placeholder="请输入" allowClear :disabled="!wxmp.editable"></a-input>
                </a-form-model-item>
              </a-col>
              <a-col :span="10">
                <a-form-model-item label="开发者密码" prop="wxAppSecret">
                  <a-input v-model="wxmp.wxAppSecret" :maxLength="50" placeholder="请输入" allowClear :disabled="!wxmp.editable"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="服务器地址" prop="wxServerUrl">
                  <a-input v-model="wxmp.wxServerUrl" :maxLength="50" placeholder="请输入" allowClear :disabled="!wxmp.editable"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { baseURL, tokenKey } from '@/libs/config';
import { getCookie, deepClone } from '@/libs/utils';
import { storeInfo, saveHsPay, saveConfig, saveWx } from '@/api/system';
import { shopInfo } from '@/api/tool';
import formMixin from '@/mixin/formMixin';

export default {
  name: 'SystemStoreForm',
  mixins: [formMixin],
  components: {},
  props: {},
  data() {
    return {
      headers: {
        authorization: 'authorization-text',
        token: getCookie(tokenKey) || ''
      },
      fileList1: [],
      fileList2: [],
      form: {
        shopName: '',
        linkman: '',
        mobile: '',
        address: '',
        remark: '',
        editable: false,
      },
      rules: {
        shopName: [
          {
            required: true,
            message: '请输入门店名称',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 150,
            message: '长度在4-50字之间',
            trigger: 'blur'
          }
        ],
        linkman: [
          {
            required: true,
            message: '请输入联系人'
          }
        ],
      },
      hsPay: {
        hsSubMerId: '',
        hsProxyId: '',
        hsPrivateKey: '',
        hsPublicKey: '',
        extAccount: '',
        editable: false,
      },
      wxmp: {
        wxAppId: '',
        wxAppSecret: '',
        wxServerUrl: '',
        token: '',
        editable: false,
      },
      wxmpRules: {
        wxAppId: [
          {
            required: true,
            message: '请输入',
          },
        ],
        wxAppSecret: [
          {
            required: true,
            message: '请输入',
          }
        ],
        wxServerUrl: [
          {
            required: true,
            message: '请输入',
          }
        ],
        token: [
          {
            required: true,
            message: '请输入',
          }
        ],
      },
      paymentRules: {
        // hsSubMerId: [
        //   {
        //     required: true,
        //     message: '请输入',
        //   },
        // ],
        // hsProxyId: [
        //   {
        //     required: true,
        //     message: '请输入',
        //   }
        // ],
        // hsPrivateKey: [
        //   {
        //     required: true,
        //     message: '请上传私钥',
        //   }
        // ],
        // hsPublicKey: [
        //   {
        //     required: true,
        //     message: '请上传公钥',
        //   }
        // ],
        // extAccount: [
        //   {
        //     required: true,
        //     message: '请输入',
        //   }
        // ],
      },
    };
  },
  computed: {
    action() {
      return baseURL + '/api/trd/shop/upload';
    },
    userInfo() {
      return this.$store.getters.getUserInfo;
    }
  },
  watch: {},
  created() {
    this.getData();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取信息
    getData() {
      storeInfo().then((data) => {
        data.editable = false;
        const params = deepClone(data);
        this.fileList1.push({
          uid: '-1',
          name: data.hsPrivateKey,
          path: data.hsPrivateKey,
          status: 'done'
        });
        this.fileList2.push({
          uid: '-1',
          name: data.hsPublicKey,
          path: data.hsPublicKey,
          status: 'done'
        });
        this.hsPay = { ...params };
        this.wxmp = { ...params };
      });
      if (this.userInfo.userDTO.shopId) {
        shopInfo(this.userInfo.userDTO.shopId).then((data) => {
          data.editable = false;
          this.form = data;
        });
      }
    },

    clearValidate(formName = 'form') {
      this.$refs[formName].clearValidate();
    },
    // 提交
    handleSave(form = 'form') {
      if (form === 'hsPay') {
        this.hsPay.hsPrivateKey = this.fileList1 && this.fileList1[0] && this.fileList1[0].path; // 私钥
        this.hsPay.hsPublicKey = this.fileList2 && this.fileList2[0] && this.fileList2[0].path; // 公钥
      }

      this.$refs[form].validate((valid) => {
        if (valid) {
          const params = deepClone(this[form]);
          delete params.editable;
          delete params.cacheData;

          if (form === 'hsPay') {
            saveHsPay(params).then(() => {
              this.$message.success('操作成功');
              this[form].editable = false;
            });
          } else if (form === 'wx') {
            saveWx(params).then(() => {
              this.$message.success('操作成功');
              this[form].editable = false;
            });
          } else {
            saveConfig(params).then((res) => {
              this.$message.success('操作成功');
              this[form].editable = false;
            });
          }
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },

    // 编辑
    handleEdit(model = 'form') {
      this[model].editable = true;
      this[model].cacheData = { ...this[model] };
    },

    // 取消编辑
    handleCancel(model = 'form') {
      Object.assign(this[model], this[model].cacheData);
      this[model].editable = false;
      delete this[model].cacheData;
      this.clearValidate(model);
    },

    handleChange(info, type) {
      let fileList = [...info.fileList];

      fileList = fileList.slice(-1);

      fileList = fileList.map(file => {
        if (file.response) {
          file.path = file.response.data.path;
        }
        return file;
      });

      if (type === 1) {
        this.hsPay.hsPrivateKey = fileList && fileList[0] && fileList[0].path; // 私钥
      } else {
        this.hsPay.hsPublicKey = fileList && fileList[0] && fileList[0].path; // 公钥
      }

      this['fileList' + type] = fileList;
    },
  }
};
</script>

<style lang="less" scoped>

</style>
